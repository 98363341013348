require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("popper.js")
require("bootstrap")
require("@fortawesome/fontawesome-pro")
require("ekko-lightbox")

import '../stylesheets/application'

require("../components/direct_upload");
require('../components/tooltip');

const images = require.context("../images", true);
const imagePath = name => images(name, true);

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox();
});
